<template>
  <div class="container">
    <div class="py-5" :class="{ 'col-md-3': signin, 'col-md-4': !signin }">
      <pvk-auth-form @signup="signin=false" v-if="signin"/>
      <pvk-signup-form @signin="signin=true" v-else/>
    </div>
  </div>
</template>

<script setup>
import PvkAuthForm from '@/components/forms/Auth'
import PvkSignupForm from '@/components/forms/Signup'
import { onMounted, ref } from 'vue'

const signin = ref(true)

onMounted(() => {
  const navbar = document.querySelector('nav')
  document.querySelector('.container').style.height = `calc(100vh - ${navbar ? navbar.offsetHeight : 0}px)`
})
</script>

<style scoped>
@media (min-width: 768px) {
  .container {
    background-image: url("/img/welcome-bg.svg");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: 65%;
    height: calc(100vh - 10px);
  }
}
</style>
