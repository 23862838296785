<template>
  <form>
    <div class="d-flex flex-row justify-content-between">
      <h5 class="mb-3">Регистрация</h5>
      <div class="d-flex flex-row text-secondary" style="cursor: pointer" @click="$emit('signin')">
        <span class="material-icons fs-5">arrow_back_ios</span>
        <h5 class="mb-3">Вход</h5>
      </div>
    </div>

    <div class="btn-group w-100 mb-3" role="group">
      <button type="button" class="btn"
              :class="{'btn-dark' : state.data.kind === 'lab', 'btn-outline-dark' : state.data.kind !== 'lab'}"
              @click="state.data.kind = 'lab'">Поверочная лаборатория
      </button>
      <button type="button" class="btn"
              :class="{'btn-dark' : state.data.kind === 'manager', 'btn-outline-dark' : state.data.kind !== 'manager'}"
              @click="state.data.kind = 'manager'">Управляющая компания
      </button>
    </div>

    <p-input placeholder="Номер телефона" v-model:value="state.data.phone" :error="v$.phone.$errors"
             maska="+7 (###) ###-##-##"/>
    <p-input class="pb-3" placeholder="Email" v-model:value="state.data.email" :error="v$.email.$errors"/>

    <div class="pb-3">
      <p-input placeholder="ИНН *" v-model:value="state.data.inn" :error="v$.inn.$errors" type="number" :maxlength="12"/>
      <p-input placeholder="Название организации *" v-model:value="state.data.companyName"
               :error="v$.companyName.$errors"
               v-if="state.data.kind === 'lab'"/>
    </div>

    <p-input placeholder="Пароль *" type="password" v-model:value="state.data.password" :error="v$.password.$errors"/>
    <p-input placeholder="Подтверждение пароля *" type="password" v-model:value="state.data.passwordRepeat"
             :error="v$.passwordRepeat.$errors"/>

    <div class="form-check mb-3">
      <input class="form-check-input" type="checkbox" v-model="state.data.acceptTerms" id="flexCheckDefault">
      <label class="form-check-label" for="flexCheckDefault">
        Регистрируясь, я принимаю
        <a href="/offer.pdf" target="_blank">условия оферты</a>
        и <a href="/policy.pdf" target="_blank">политики конфиденциальности</a>
      </label>
    </div>

    <button class="btn btn-dark w-100 mb-3" @click.prevent="signup" :disabled="requiredNotFilled">
      Зарегистрироваться
    </button>
    <p-server-error :error="state.serverError"/>
    <div></div>
  </form>
</template>

<script setup>
import api from '@/api'
import urls from '@/api/urls'
import { requiredRu } from '@/common'
import PServerError from '@/components/ui/ServerError'
import useVuelidate from '@vuelidate/core'
import { email, helpers } from '@vuelidate/validators'
import { computed, reactive } from 'vue'
import { useRouter } from 'vue-router'
import { useStore } from 'vuex'

const store = useStore()
const router = useRouter()

const state = reactive({
  data: {
    kind: 'lab',
    phone: null,
    email: null,
    inn: null,
    companyName: null,
    password: null,
    passwordRepeat: null,
    acceptTerms: false,
  },
  serverError: null,
})

const phoneOrEmail = helpers.withMessage(
  'Необходимо указать email или номер телефона',
  () => Boolean(state.data.email || state.data.phone)
)

const rules = {
  kind: {},
  phone: {
    phoneOrEmail,
    valid: helpers.withMessage(
      'Пожалуйста, введите верный номер телефона',
      (value) => !value || value.length === 18
    ),
  },
  email: {
    phoneOrEmail,
    email: helpers.withMessage('Пожалуйста, введите верный email', email),
  },
  inn: { requiredRu },
  companyName: {
    requiredLab: helpers.withMessage('Это поле не может быть пустым', (value) => value || state.data.kind === 'manager'),
  },
  password: {
    requiredRu,
    strong: helpers.withMessage(
      'Пароль должен быть не короче 8 символов, а также содержать хотябы одну букву и цифру',
      (value) => RegExp(process.env.VUE_APP_PASSWORD_RE).test(value)
    ),
  },
  passwordRepeat: {
    requiredRu,
    sameAsPassword: helpers.withMessage('Пароли не совпадают', (value) => value === state.data.password),
  },
  acceptTerms: { requiredRu },
}
const v$ = useVuelidate(rules, state.data)

const requiredNotFilled = computed(() => {
  let filled = 0
  for (const field in state.data) {
    if (rules[field].requiredRu) {
      filled += state.data[field] ? 0 : 1
    }
  }
  return filled
})

const signup = async () => {
  state.serverError = null
  const ok = await v$.value.$validate()
  if (!ok) {
    return
  }
  try {
    const response = await api.post(urls.AUTH.SIGNUP, state.data)
    await store.dispatch('login', response)
    await router.push({ name: 'profile' })
  } catch (err) {
    state.serverError = err.message
  }
}
</script>

<style scoped>

</style>
