<template>
  <form>
    <h5 class="mb-3">Вход</h5>

    <div class="mb-3">
      <p-username-input
        @update:username="store.commit('setUsername', $event)"
        :username="username" :error="v$.username.$error"
      />
    </div>

    <p-input placeholder="Пароль" type="password" v-model:value="state.data.password" :error="v$.password.$errors"/>

    <button class="btn btn-dark w-100 mb-3" @click.prevent="signin">Войти</button>
    <p-server-error :error="state.serverError"/>

<!--    <router-link :to="{name: 'reset-password'}">-->
<!--      <div class="text-end mb-3">Забыли пароль?</div>-->
<!--    </router-link>-->

    <button type="button" class="btn btn-outline-dark w-100 mb-3" @click="$emit('signup')">Зарегистрироваться
    </button>
  </form>
</template>

<script setup>
import api from '@/api'
import urls from '@/api/urls'
import { requiredRu } from '@/common'
import PServerError from '@/components/ui/ServerError'
import PUsernameInput from '@/components/ui/UsernameInput'
import useVuelidate from '@vuelidate/core'
import { computed, defineEmits, reactive } from 'vue'
import { useRouter } from 'vue-router'
import { useStore } from 'vuex'

defineEmits(['signup'])

const store = useStore()
const router = useRouter()

const username = computed(() => store.state.username)
const state = reactive({
  data: {
    username,
    password: null,
  },
  serverError: null,
})
const rules = {
  username: { requiredRu },
  password: { requiredRu },
}
const v$ = useVuelidate(rules, state.data)

const signin = async () => {
  state.serverError = null
  const ok = await v$.value.$validate()

  if (!ok) {
    return
  }
  try {
    const response = await api.post(urls.AUTH.SIGNIN, state.data)
    await store.dispatch('login', response)
    await router.push({ name: 'profile' })
  } catch (err) {
    state.serverError = err.message
  }
}
</script>

<style scoped>

</style>
